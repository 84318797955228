import { ComboBox, FormComboboxInterface, RecordInterface } from '@components/common/Forms';
import { Avatar } from '@components/common';
import { useMemo } from 'react';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';

interface IProps {
  recordName: string;
  record: RecordInterface;
  placeholder?: string;
  locale: Locale;
  comboboxProps?: Omit<FormComboboxInterface, 'options'>;
  withClaimedInfo?: boolean;
}

/**
 * @description Combobox for selecting employer
 * @param recordName
 * @param record
 * @param placeholder
 * @param locale
 * @param comboboxProps - The combobox props
 * @param withClaimedInfo - If the employer is already claimed
 */
export function EmployerTooltipComboBox({
  recordName, record, placeholder = '', locale, comboboxProps = {
    required: true,
    minLength: 1,
  }, withClaimedInfo,
}: IProps) {
  const finalProps = useMemo(
    () => ({ ...record(recordName), ...comboboxProps }),
    [recordName, record, comboboxProps],
  );

  // Translation
  const { t } = useTranslation('common');

  return (
    <ComboBox
      required
      minLength={3}
      placeholder={placeholder}
      options={{
        valueFromKey: 'name',
        isItemDisabled: (item) => !!withClaimedInfo && !!item['claimed' as keyof typeof item],
        apiList: {
          fetchUrl: '/v1/employer-tooltip',
          fetcherConfig: {
            headers: {
              'Accept-Language': locale,
            },
          },
          queryParams: (query: string) => ({ q: query }),
        },
      }}
      renderList={(
        item,
        index,
        listKey,
        getItemProps,
        listStyling,
        highlightedIndex,
        isDisabled,
      ) => {
        const logo: string = item['logo' as keyof typeof item];
        const name: string = item['name' as keyof typeof item];
        const location: string = item['location' as keyof typeof item];

        return (
          <li
            key={listKey}
            className={`${listStyling} ${isDisabled ? 'cursor-not-allowed bg-surface-50' : ''}`}
            {...getItemProps({ item, index })}
          >
            <Avatar
              imgPath={logo}
              imgFolder="employer_logo"
              extractImageName
              aspectRatio="square-fit"
              name={name}
              id={name?.charCodeAt(0)}
              size="sm"
              className={clsx(
                'shrink-0',
                highlightedIndex === index && 'invert',
              )}
            />
            <div className="ml-4 flex flex-col">
              <span>{name}</span>
              <span className={`${highlightedIndex === index ? 'text-white' : 'text-ink-medium'} -mt-0.5 text-tiny font-normal transition-all duration-150`}>
                {location}
              </span>
              {isDisabled && (
                <span
                  className={clsx(
                    'text-tiny font-semibold',
                    highlightedIndex === index ? 'text-white' : 'text-ink-light',
                  )}
                >
                  { t('tooltip.employer.already_claimed') }
                </span>
              )}
            </div>
          </li>
        );
      }}
      {...finalProps}
    />
  );
}
