import { WebSocketCallbackData, WebSocketResponseMessage } from '@type/web-socket';

export const OPEN_API_WS_URL = process.env.NEXT_PUBLIC_OPEN_API_WS_URL as string;

let webSocket: WebSocket | null = null;
let messageString = '';


/**
 * @description check if WebSocket connection is closed
 */
const isClosedWebSocketConnection = (): boolean => !webSocket || [WebSocket.CLOSED as number, WebSocket.CLOSING as number].includes(webSocket.readyState);


/**
 * @description close WebSocket connection
 */
export function closeWSConnection() {
  if (webSocket && ![WebSocket.CONNECTING as number, WebSocket.CLOSING as number, WebSocket.CLOSED as number].includes(webSocket.readyState)) {
    webSocket.close();
    webSocket = null;
  }
  messageString = '';
}


/**
 * @description listen to messages from server
 * @param onMessageReceivedCallback
 */
function listenToMessages(onMessageReceivedCallback: (data: WebSocketCallbackData) => void) {
  if (!webSocket) {
    return;
  }
  // Receive message from server word by word. Display the words as they are received.
  webSocket.onmessage = (event) => {
    const data = JSON.parse(event.data as string) as WebSocketResponseMessage;
    if (data.sender === 'bot') {
      if (data.type === 'stream') {
        messageString += data.message;
        onMessageReceivedCallback({ message: messageString, isSuccess: true });
      }

      if (data.type === 'start') {
        messageString = '';
        onMessageReceivedCallback({ isStart: true });
      }

      if (data.type === 'end') {
        onMessageReceivedCallback({ message: messageString, isEnd: true });
        messageString = '';
      }

      if (data.type === 'error') {
        onMessageReceivedCallback({ isError: true });
        messageString = '';
      }
    }
  };
}

/**
 * @description listen to any errors from server
 * @param onMessageReceivedCallback
 */
function listenToErrors(onMessageReceivedCallback: (data: WebSocketCallbackData) => void) {
  if (!webSocket) {
    return;
  }
  webSocket.onerror = () => {
    onMessageReceivedCallback({ isError: true });
    closeWSConnection();
  };
}

/**
 * @description initialize WebSocket connection to a specific url
 * @param url
 * @param onMessageReceivedCallback
 */
export function initConnection(url: string, onMessageReceivedCallback: (data: WebSocketCallbackData) => void) {
  if (isClosedWebSocketConnection()) {
    webSocket = new WebSocket(url);

    listenToMessages(onMessageReceivedCallback);
    listenToErrors(onMessageReceivedCallback);
  }

  messageString = '';
}


/**
 * @description send message to server and listen to response
 * ** Instatiate WebSocket connection if not already initialized
 * @param url
 * @param data
 * @param onMessageReceivedCallback
 */
export function sendWSMessageAndListen(url: string, data: string, onMessageReceivedCallback: (data: WebSocketCallbackData) => void) {
  if (!data) {
    return;
  }

  try {
    // init connection if not already initialized or if connection is closed due to some error or timeout
    if (isClosedWebSocketConnection()) {
      initConnection(url, onMessageReceivedCallback);
    }

    // send message when connection is open
    if (webSocket?.readyState === WebSocket.OPEN) {
      webSocket.send(data);
    } else {
      webSocket?.addEventListener('open', () => {
        webSocket?.send(data);
      });
    }
  } catch (e) {
    console.error('WebSocket', e);
    onMessageReceivedCallback({ message: '', isError: true });
    closeWSConnection();
  }
}
