import { useTranslation } from 'next-i18next';
import {
  ApiDeleteProfileExperience,
  ApiProfileExperienceOk,
} from 'src/types/v1-api-types';
import {
  Button,
  DatePickerInput, Input, Label, RadioText, Select, Textarea,
} from '@components/common/Forms';
import { ArrowPathIcon, InformationCircleIcon } from '@heroicons/react/24/solid';

import React, {
  FormEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  fetcher, isApiV1Errors, isDeleteProfileExperienceOk,
} from '@utils/data-fetching';
import { bjToast, RatingWidget, Tooltip } from '@components/common';
import { useBjForm } from '@hooks/useBjForm';
import { subYears } from 'date-fns';
import { EmployerTooltipComboBox } from '@components/features/combobox/EmployerTooltipComboBox';
import { LocationComboBox } from '@components/features/combobox/LocationComboBox';
import { FooterButtonsSaveDelete } from '@components/templates/profile/common/FooterButtonsSaveDelete';
import { useSwrStaticSalaryCurrencies } from '@hooks/useSwrApi/endpoints/static/useSwrStaticSalaryCurrencies';
import { JobTitleComboBox } from '@components/features/combobox';
import { ArrayFilterItem } from '@utils/arrays/filter-strings';
import { sendWSMessageAndListen, closeWSConnection } from '@services/webSocketResumeExperience/webSocketResumeExperience';
import { WebSocketCallbackData } from '@type/web-socket';
import { LogoBestjobsAI } from '@components/layout/LogoBj/LogoBestjobsAI';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';

const MIN_DATE = subYears(new Date(), 65);
const NOW_DATE = new Date();

interface IProps {
  experience: Partial<ApiProfileExperienceOk> | null;
  locale: Locale;
  onSuccessSubmit: () => void;
  withSuggestions?: boolean;
}

export const ProfessionalExperienceForm = ({
  experience, locale, onSuccessSubmit, withSuggestions,
}: IProps) => {
  const { t } = useTranslation();
  const [currentlyWorkingHere, setCurrentlyWorkingHere] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { data: currencies = [] } = useSwrStaticSalaryCurrencies(locale);

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [showBiaDescription, setBiaDescription] = useState<boolean>(!experience?.description);
  const [starsSelected, setStarsSelected] = useState<number>(experience?.rating || 0);

  const formRef = useRef(null);


  const submitMethod = (() => {
    if (withSuggestions) {
      return 'POST';
    }
    if (experience) {
      return 'PUT';
    }
    return 'POST';
  })();

  const submitURL = (() => {
    if (withSuggestions) {
      return '/v1/resume/work-experience';
    }
    if (experience) {
      return `/v1/resume/work-experience/${experience.id}`;
    }
    return '/v1/resume/work-experience';
  })();

  const {
    record, formStatus, handleSubmit, isPosting, elementsRefs, setFormValues,
  } = useBjForm({
    withDefaultValues: experience,
    submitMethod,
    submitURL,
  });

  const onChangeStars = (value: number) => {
    elementsRefs.rating.current?.setElementValue(value ? `${value}` : '');
    setStarsSelected(value);
  };

  // handles success response
  useEffect(() => {
    if (formStatus.actionStatus === 'success') {
      if (withSuggestions) {
        sendGoogleTagEvent('feed_action_created_experience', 'Custom');
      }
      onSuccessSubmit();
      bjToast.success(t('experience.saved'));
    }
  }, [formStatus, t, onSuccessSubmit, withSuggestions]);

  // set experience start and end dates
  useEffect(() => {
    if (!experience) {
      return;
    }

    if (!experience.end) {
      setCurrentlyWorkingHere(true);
    }

    if (experience.start) {
      setStartDate(new Date(experience.start));
    }

    if (experience.end) {
      setEndDate(new Date(experience.end));
    }
  }, [experience]);


  // on checkbox change
  const onChangeCurrentlyWorkingHere = () => {
    setCurrentlyWorkingHere((prevState) => !prevState);
    if (!currentlyWorkingHere) {
      setEndDate(null);
    }
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!elementsRefs['salary.amount'].current?.getElementValue()) {
      elementsRefs['salary.confidential'].current?.setElementValue('');
      elementsRefs['salary.currency'].current?.setElementValue('');
    }

    handleSubmit(e);
  };

  const onDeleteExperience = () => {
    if (!experience) {
      return;
    }

    setIsDeleteLoading(true);
    setIsDeleteModalOpened(false);

    void fetcher<ApiDeleteProfileExperience>(`/v1/resume/work-experience/${experience.id}`, locale, { method: 'DELETE' })
      .then((response) => {
        if (isApiV1Errors(response)) {
          bjToast.error(response.errors[0].message);
        }

        if (isDeleteProfileExperienceOk(response)) {
          onSuccessSubmit();
          bjToast.success(t('experience.deleted'));
        }
        setIsDeleteLoading(false);
      });
  };

  const getGeneratedExperience = useCallback((data: WebSocketCallbackData) => {
    const {
      isSuccess, isStart, isError, isEnd,
    } = data;

    if (isStart) {
      setBiaDescription(false);
    }

    if (isSuccess) {
      setFormValues({ description: data?.message });
    }

    if (isEnd) {
      closeWSConnection();
    }

    if (isError) {
      bjToast.error(t('global.something.went.wrong'));
      closeWSConnection();
    }
  }, [t, setFormValues]);

  const generateBiaExperience = () => {
    const title = elementsRefs.title.current?.getElementValue() as string;

    if (title.length < 5) {
      bjToast.error(t('global.required.experience.title.level.bia'));
    } else {
      sendWSMessageAndListen({ title, category: 'experience', language: locale }, (data) => getGeneratedExperience(data));
    }
  };

  const regenerateBiaExperience = () => {
    const prevDescription = elementsRefs.description.current?.getElementValue() as string;
    const title = elementsRefs.title.current?.getElementValue() as string;

    if (title.length < 5) {
      bjToast.error(t('global.required.experience.title.level.bia'));
    } else {
      sendWSMessageAndListen({
        title, category: 'experience', language: locale, existing_content: prevDescription,
      }, (data) => getGeneratedExperience(data));
    }
  };


  return (
    <div className="relative flex animate-appear flex-col">


      <form noValidate onSubmit={onFormSubmit} ref={formRef}>
        <div className="pt-4">
          <div className="grid place-items-start gap-7 sm:grid-cols-2">
            <div className="w-full">
              {/* job title autocomplete. uses name and id in API call */}
              <Label htmlFor="title" description={t('no.experience.post.label_new')} required>
                <JobTitleComboBox
                  record={record}
                  recordName="title"
                  comboboxProps={{
                    customValidityMessages: { valueMissing: t('error.message.experience.name') },
                    required: true,
                    minLength: 5,
                    onSelectedItemChange: (item: ArrayFilterItem | null | undefined) => {
                      elementsRefs.predictedGroup.current?.setElementValue(item ? item['id' as keyof typeof item] : '');
                    },
                  }}
                />
              </Label>
            </div>
            {/* hidden input for predicted group id. it is set from combobox */}
            <Input hidden type="text" {...record('predictedGroup')} />
            <Input hidden type="text" {...record('companyId')} />

            {/* company combobox */}
            <div className="w-full">
              <Label htmlFor="company" description={t('global.label.employer_name')} required>
                <EmployerTooltipComboBox
                  record={record}
                  recordName="company"
                  locale={locale}
                  comboboxProps={{
                    required: true,
                    minLength: 3,
                    onSelectedItemChange: (item: ArrayFilterItem | null | undefined) => {
                      elementsRefs.companyId.current?.setElementValue(item ? item['id' as keyof typeof item] : '');
                    },
                  }}
                />
              </Label>
            </div>
          </div>

          <div className="mt-4 grid place-items-start gap-7 sm:grid-cols-2">

            {/* location autocomplete */}
            <div className="w-full">
              <Label htmlFor="location" description={t('global.location.label')}>
                <LocationComboBox record={record} recordName="location" locale={locale} />
              </Label>
            </div>

            <div className="w-full">
              <Label description={t('experience.rating.placeholder')}>
                <div className="mb-4 flex items-center gap-6 pt-3">
                  <RatingWidget
                    defaultValue={starsSelected}
                    onChange={(newValue) => onChangeStars(newValue)}
                    emptyStarClassName="text-ink"
                  />
                  <ArrowPathIcon onClick={() => onChangeStars(0)} className="size-6 cursor-pointer" />
                </div>
                <Input hidden type="number" {...record('rating', { convertValue: 'toNumber' })} />
              </Label>
            </div>

          </div>

          {/* checkbox for currently working here. not sent in API */}
          <div className="grid place-items-start gap-7 sm:my-2 sm:grid-cols-2">
            <Label htmlFor="currentlyWorkHere" className="!w-fit">
              <Input
                type="checkbox"
                checked={currentlyWorkingHere}
                onChange={onChangeCurrentlyWorkingHere}
                id="currentlyWorkHere"
                name="currentlyWorkHere"
              />
              <RadioText>{t('cv-i-still-work-here')}</RadioText>
            </Label>
          </div>

          {/* salary start end date */}
          <div className="my-2 grid place-items-start gap-7 sm:grid-cols-2">
            <div className="grid w-full gap-7 sm:grid-cols-2 ">
              {/* start date */}
              <Label className="flex flex-col items-start" htmlFor="start" description={t('experience.start.label')} required>
                <DatePickerInput
                  {...record('start')}
                  customValidityMessages={{ valueMissing: t('error.message.study.period') }}
                  required
                  placeholder={t('global.yearmonth.placeholder')}
                  date={startDate}
                  setDate={(date) => setStartDate(date)}
                  pickerType="month"
                  pickerProps={{
                    minDate: MIN_DATE,
                    maxDate: endDate ?? NOW_DATE,
                  }}
                />
              </Label>
              {/* salary end date - shows if not currently working on job */}
              {!currentlyWorkingHere && (
              <Label htmlFor="end" className="flex flex-col items-start" description={t('experience.end.label')} required>
                <DatePickerInput
                  {...record('end')}
                  customValidityMessages={{ valueMissing: t('error.message.study.period') }}
                  required
                  placeholder={t('global.yearmonth.placeholder')}
                  date={endDate}
                  setDate={(date) => setEndDate(date)}
                  pickerType="month"
                  pickerProps={{
                    minDate: startDate ?? MIN_DATE,
                    maxDate: NOW_DATE,
                  }}
                />
              </Label>
              )}
            </div>

          </div>

          {/* salary */}
          <div className="my-4 grid place-items-start items-center gap-7 sm:grid-cols-2">

            {/* salary field */}
            <div className="w-full sm:items-center">
              <Label className="w-full">
                <div className="mb-1 flex items-center">
                  <label>
                    <span className="inline-block text-sm">
                      {t('job.add.label.net_salary')}
                    </span>
                  </label>
                  <div className="w-fit">
                    <Tooltip tooltipText={t('cv.detail.salary.explanation')} colorScheme="black" displayStyle="block" position="top">
                      <InformationCircleIcon className="ml-1 size-5 text-notificationInfo" />
                    </Tooltip>
                  </div>
                </div>

                <div className="flex w-full flex-col">
                  <div className="flex">

                    {/* salary amount */}
                    <Label htmlFor="salary.amount" className="!mt-0 w-1/2">
                      <Input
                        type="number"
                        min={1}
                        placeholder={t('job.add.placeholder.salary')}
                        className="rounded-r-none"
                        {...record('salary.amount', { convertValue: 'toNumber' })}
                      />
                    </Label>

                    {/* currencies */}
                    <Label className="!mt-0 w-1/2">
                      <Select
                        {...record('salary.currency')}
                        className="rounded-l-none border-l-0"
                      >
                        {currencies.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Select>
                    </Label>

                  </div>
                </div>
              </Label>
            </div>

            {/* salary confidential field */}
            <div className="w-full pt-0 md:pt-6">
              <Label htmlFor="salary.confidential">
                <Input type="checkbox" {...record('salary.confidential', { defaultValue: 'true', convertValue: 'toBoolean' })} />
                <RadioText>{t('cv.detail.salary_confidential')}</RadioText>
              </Label>
            </div>
          </div>
        </div>

        {/* description */}
        <div className="relative">
          <Label description={t('experience.description.label')}>

            <div className="relative">

              {showBiaDescription
                  && (
                  <div className="absolute top-0 size-full rounded-sm border bg-surface">
                    <div className="p-5 text-center">
                      <div className="flex w-full items-center justify-center">
                        <LogoBestjobsAI />
                      </div>
                      <div className="mt-2 text-sm">{t('openai.add.experience.title.bia')}</div>
                      <div className="text-sm">{t('openai.add.experience.subtitle.bia')}</div>

                    </div>
                  </div>
                  )}

              <Textarea rows={5} {...record('description')} />
            </div>

            {showBiaDescription
                && (
                <div className="flex flex-col-reverse justify-center pt-4 md:flex-row">
                  <Button size="xs" type="button" color="primary" className="text-primary md:mr-5" styling="none" onClick={() => setBiaDescription(false)}>{t('openai.add.experience.content.manually')}</Button>
                  <Button size="sm" type="button" color="primary" styling="outline" rounding="full" className="mb-3 md:mb-0" onClick={generateBiaExperience}>{t('openai.add.experience.content.bia')}</Button>
                </div>
                )}


            <div className={`${showBiaDescription ? 'hidden' : 'my-2 text-center'} `}>
              <Button size="sm" type="button" color="primary" className="font-thin text-primary md:mr-5" styling="none" onClick={regenerateBiaExperience}>{t('openai.add.experience.content.regenerate')}</Button>
            </div>

          </Label>


        </div>


        {/* footer buttons for save and delete */}
        <FooterButtonsSaveDelete
          isSaveLoading={isPosting}
          isDeleteLoading={isDeleteLoading}
          deleteModalProps={!experience ? undefined : {
            isOpen: isDeleteModalOpened,
            setIsOpen: (val) => setIsDeleteModalOpened(val),
            onDelete: onDeleteExperience,
            description: t('experience.delete').replace('%name%', experience?.title || ''),
            confirmText: t('experience.delete.yes'),
            cancelText: t('experience.delete.cancel'),
          }}
        />
      </form>
    </div>
  );
};
