import { ComboBox, FormComboboxInterface, RecordInterface } from '@components/common/Forms';
import { ApiJobTitleAutocompleteOk } from '@type/v1-api-types';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';

interface IProps {
  recordName?: string;
  record?: RecordInterface;
  comboboxProps?: Omit<FormComboboxInterface, 'options'>;
  withSearchIcon?: boolean;
}

/**
 * @description Combobox for selecting job title
 * @param recordName - The record name used with bjForm
 * @param record - The record function to use with bjForm
 * @param comboboxProps - The combobox props
 * @param withSearchIcon - If the combobox should have a search icon in the left side
 */
export function JobTitleComboBox({
  recordName, record, withSearchIcon = false, comboboxProps = {
    required: true,
    minLength: 1,
  },
}: IProps) {
  // Build record props if we have a record and a record name to use with bjForm
  const finalProps = useMemo(() => {
    // Build record props if we have a record and a record name to use with bjForm
    const recordProps = record && recordName ? { ...record(recordName) } : {};

    if (!withSearchIcon) {
      return {
        ...recordProps,
        ...comboboxProps,
      };
    }

    // Add search icon to the combobox
    const finalComboboxProps = {
      ...comboboxProps,
      className: comboboxProps.className ? `${comboboxProps.className} pl-12` : 'pl-12',
    };

    return {
      ...recordProps,
      ...finalComboboxProps,
    };
  }, [recordName, record, comboboxProps, withSearchIcon]);

  return (
    <div className="relative w-full">
      {/* Show search icon if available */}
      {
        withSearchIcon && (
          <div className="absolute z-1 w-fit pl-4 inset-0 top-3">
            <MagnifyingGlassIcon className="w-6 h-6" />
          </div>
        )
      }

      {/* Combobox component */}
      <ComboBox
        options={{
          valueFromKey: 'name',
          apiList: {
            fetchUrl: '/job-title-autocomplete',
            queryParams: (query: string) => ({ term: query }),
          },
        }}
        renderList={(item, index, listKey, getItemProps, listStyling, highlightedIndex) => {
          // add a type to make TS behave nicely; we already checked for errors object inside Combobox fetch
          const job = item as ApiJobTitleAutocompleteOk;
          return (
            <li key={listKey} className={`${listStyling} flex-col`} {...getItemProps({ item, index })}>
              <span>{job.name}</span>
              <span className={`${highlightedIndex === index ? 'text-white' : 'text-ink-medium'} text-tiny transition-all duration-150 -mt-0.5 font-normal`}>
                { // we might have multiple domains for a job name, spread them nicely
                    job.domains && (job.domains).map((domain, i) => {
                      if (i === 0) return (domain.name);
                      return (`, ${domain.name}`);
                    })
                }
              </span>
            </li>
          );
        }}
        {...finalProps}
      />
    </div>
  );
}
