import { FormComboboxInterface, RecordInterface } from '@type/form-types';
import { ComboBox } from '@components/common/Forms';
import { useMemo } from 'react';
import { MapPinIcon } from '@heroicons/react/24/outline';

interface IProps {
  recordName?: string;
  record?: RecordInterface;
  comboboxProps?: Omit<FormComboboxInterface, 'options'>;
  withIcon?: boolean;
  locale: Locale;
}

/**
 * @description Combobox for selecting location
 * @param recordName - The record name used with bjForm
 * @param record - The record function to use with bjForm
 * @param comboboxProps - The combobox props
 * @param withIcon - If the combobox should have a pin icon in the left side
 * @param locale - The current app locale
 */
export const LocationComboBox = ({
  recordName, record, withIcon = false, comboboxProps = {
    minLength: 1,
  }, locale,
}: IProps) => {
  // Build record props if we have a record and a record name to use with bjForm
  const finalProps = useMemo(() => {
    // Build record props if we have a record and a record name to use with bjForm
    const recordProps = record && recordName ? { ...record(recordName) } : {};

    if (!withIcon) {
      return {
        ...recordProps,
        ...comboboxProps,
      };
    }

    // Add search icon to the combobox
    const finalComboboxProps = {
      ...comboboxProps,
      className: comboboxProps.className ? `${comboboxProps.className} pl-12` : 'pl-12',
    };

    return {
      ...recordProps,
      ...finalComboboxProps,
    };
  }, [recordName, record, comboboxProps, withIcon]);

  return (
    <div className="relative w-full">
      {/* Show pin icon if available */}
      {
        withIcon && (
          <div className="absolute z-1 w-fit pl-4 inset-0 top-3">
            <MapPinIcon className="w-6 h-6" />
          </div>
        )
      }
      <ComboBox
        options={{
          valueFromKey: 'locality',
          apiList: {
            fetchUrl: '/v1/location-tooltip',
            fetcherConfig: {
              headers: {
                'Accept-Language': locale,
              },
            },
            queryParams: (query: string) => ({ location: query }),
          },
        }}
        {...finalProps}
      />
    </div>
  );
};
